import React from "react";
import { supabase } from "../Components/supabaseClient";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import Header from './Header';

const SignUp = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const navigate = useNavigate();

  const signUserUp = async (randomCode) => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });
    if (error) {
      alert("Error signing up: " + error.message);
    } else {
      navigate("/otpverify", {
        state: { randomCode: randomCode, email: email, password: password },
      });
    }
  };

  const callEmailVerifyFunction = async () => {
    const randomCode = Math.floor(1000 + Math.random() * 9000);
    try {
      const res = await fetch(
        "https://bxzuxbvzbgkrnunmflgc.supabase.co/functions/v1/proxy-function",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from: "FixtureFix <onboarding@fixturefix.co.uk>",
            to: [email],
            subject: "FixtureFix Email Verification",
            html: `<p>Enter the code into the verification screen and your email will be validated</strong><strong> <br/>${randomCode}</strong>`,
          }),
        }
      );
      const data = await res.json();
      res.status === 200 ? signUserUp(randomCode) : alert("Error: " + data.message);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
    <Header />
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      style={{
        background: "linear-gradient(135deg, #BFD9FF, #8CBBFE)",
      }}
    >
      <Container maxWidth="xs">
        <Card style={{ padding: "2rem", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" gutterBottom style={{ fontWeight: "bold" }}>
                Sign Up
              </Typography>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                error={password !== confirmPassword && confirmPassword.length > 0}
                helperText={
                  password !== confirmPassword && confirmPassword.length > 0
                    ? "Passwords do not match"
                    : ""
                }
                label="Confirm Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  marginTop: "1rem",
                  borderRadius: "25px",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
                onClick={callEmailVerifyFunction}
              >
                Sign Up
              </Button>
              <Typography
                variant="body2"
                style={{ marginTop: "1rem", color: "#6b6b6b" }}
              >
                Already have an account?{" "}
                <Link href="/login" underline="hover" style={{ color: "#6558f5" }}>
                  Login
                </Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Typography
          variant="body2"
          style={{
            marginTop: "2rem",
            textAlign: "center",
            color: "#000B0C",
          }}
        >
          © 2025 FixtureFix. All Rights Reserved.
        </Typography>
      </Container>
    </Box>
    </>
  );
};

export default SignUp;
