import React, { useEffect, useState } from "react";
import Header from './Header';
import { supabase } from '../Components/supabaseClient';
import { useNavigate } from "react-router-dom";

const ListOfGames = () => {
    const [games, setGames] = useState([]);
    const [filter, setFilter] = useState("all");
    const navigate = useNavigate();

    useEffect(() => {
        const getListOfGames = async () => {
            const { data, error } = await supabase.from("fixture").select("*").gte("fixture_date", new Date().toISOString());
            if (error) {
                console.log(error);
            } else {
                setGames(data);
            }
        };
        getListOfGames();
    }, []);

    const handleClubClick = (clubName) => {
        navigate('/clubprofile', {
            state: {
                clubName: clubName
            }
        });
    };

    const filteredGames = games.filter((game) => {
        if (filter === "all") return true;
        return game.fixture_venue === filter;
    });

    return (
        <>
        <Header />
        <div style={styles.container}>
            
            <h1 style={styles.title}>List of Games</h1>

            {/* Filter Buttons */}
            <div style={styles.filterContainer}>
                <button
                    style={{
                        ...styles.filterButton,
                        backgroundColor: filter === "all" ? "#6558f5" : "#ddd",
                        color: filter === "all" ? "#fff" : "#333",
                    }}
                    onClick={() => setFilter("all")}
                >
                    All
                </button>
                <button
                    style={{
                        ...styles.filterButton,
                        backgroundColor: filter === "home" ? "#6558f5" : "#ddd",
                        color: filter === "home" ? "#fff" : "#333",
                    }}
                    onClick={() => setFilter("home")}
                >
                    Home
                </button>
                <button
                    style={{
                        ...styles.filterButton,
                        backgroundColor: filter === "away" ? "#6558f5" : "#ddd",
                        color: filter === "away" ? "#fff" : "#333",
                    }}
                    onClick={() => setFilter("away")}
                >
                    Away
                </button>
            </div>

            <ul style={styles.list}>
                {filteredGames.map((game) => (
                    <li key={game.id} style={styles.gameItem}>
                        <h2 
                            style={styles.clubName} 
                            onClick={() => handleClubClick(game.club_name)}
                        >
                            {game.club_name}
                        </h2>
                        <p style={styles.details}>Date: {game.fixture_date}</p>
                        <p style={styles.details}>Venue: {game.fixture_venue}</p>
                        <p style={styles.details}>Email: {game.fixture_email}</p>
                    </li>
                ))}
            </ul>
        </div>
        </>
    );
};

const styles = {
    container: {
        padding: "20px",
        fontFamily: "'Arial', sans-serif",
        backgroundColor: "#f9f9f9",
        minHeight: "100vh"
    },
    title: {
        fontSize: "24px",
        color: "#333",
        marginBottom: "20px",
        textAlign: "center"
    },
    filterContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
        gap: "10px"
    },
    filterButton: {
        padding: "10px 20px",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
        transition: "background-color 0.3s",
    },
    list: {
        listStyleType: "none",
        padding: 0,
        margin: 0
    },
    gameItem: {
        backgroundColor: "#fff",
        padding: "15px",
        margin: "10px 0",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        cursor: "pointer"
    },
    clubName: {
        fontSize: "18px",
        color: "#6558f5",
        marginBottom: "10px",
        cursor: "pointer",
        textDecoration: "underline"
    },
    details: {
        fontSize: "14px",
        color: "#555"
    }
};

export default ListOfGames;
