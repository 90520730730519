import React from 'react';
import {
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import Header from './Header'
import CookieConsent from "react-cookie-consent";

const Home = () => {
  const iosLogo = () => (
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
      alt="Download on the App Store"
      style={{ height: "60px", cursor: "pointer" }}
      onClick={() => window.location.href = "https://apps.apple.com/gb/app/fixturefix/id6464330205"}
    />
  );

  const androidLogo = () => (
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
      alt="Get it on Google Play"
      style={{ height: "60px", cursor: "pointer" }}
      onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.fixturefix"}
    />
  );

  return (
    <Box>
      <Header />
      {/* Hero Section */}
      <Box sx={{ bgcolor: '#1e3a8a', color: 'white', py: 10, px: 2 }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          <Typography variant="h1" sx={{ fontSize: '3.5rem', fontWeight: 'bold', mb: 4 }}>
            Cricket Fixture App: Organize Cricket Matches Easily
          </Typography>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Simplify your cricket fixture scheduling with FixtureFix
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            The perfect tool for village cricket and beyond
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            {iosLogo()}
            {androidLogo()}
          </Box>
        </Container>
      </Box>

      {/* About Section */}
      <Container maxWidth="md" sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h3" sx={{ color: '#1e3a8a', fontWeight: 'bold', mb: 4 }}>
          Village Cricket Tools Made Simple
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '1.1rem' }}>
          FixtureFix is your ultimate cricket fixture app designed for organizing matches effortlessly. Whether you're managing a village cricket team or a club, our platform offers tools to add fixture dates, browse other teams' schedules, and confirm matches seamlessly.
        </Typography>
      </Container>

      {/* Sign Up Section */}
      <Container maxWidth="md" sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h3" sx={{ color: '#1e3a8a', fontWeight: 'bold', mb: 4 }}>
          Get Started with FixtureFix
        </Typography>
        <Typography variant="h5" sx={{ color: '#1e3a8a', fontWeight: 'bold', mb: 4 }}>
          To get the most out of FixtureFix, sign up or log in to your account.
        </Typography>
        <Button variant="contained" size="large" sx={{ bgcolor: '#4caf50', color: 'white', mr: 2 }} onClick={() => window.location.href = '/signup'}>
          Sign Up
        </Button>
        <Button variant="contained" size="large" sx={{ bgcolor: '#1e3a8a', color: 'white', mr: 2 }} onClick={() => window.location.href = '/login'}>
          Sign In
        </Button>
      </Container>

      {/* How It Works */}
      <Box sx={{ bgcolor: '#f8f9fa', py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" sx={{ color: '#1e3a8a', fontWeight: 'bold', mb: 6, textAlign: 'center' }}>
            Organize Cricket Matches Easily
          </Typography>
          <Grid container spacing={4}>
            {[
              { icon: '👥', title: 'Register your team', desc: 'Sign up to create your team profile and start scheduling fixtures.' },
              { icon: '📅', title: 'Add fixture dates', desc: 'Easily input your team\'s available dates for matches.' },
              { icon: '🔍', title: 'Find opponents', desc: 'Browse other cricket teams\' schedules and match availability.' },
              { icon: '💬', title: 'Confirm and play', desc: 'Communicate directly with other teams to finalize matches.' }
            ].map((item, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Card sx={{ height: '100%' }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Box sx={{
                      width: 48,
                      height: 48,
                      bgcolor: '#e8f5e9',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mx: 'auto',
                      mb: 2
                    }}>
                      <Typography>{item.icon}</Typography>
                    </Box>
                    <Typography variant="h6" sx={{ color: '#1e3a8a', fontWeight: 'bold', mb: 1 }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.desc}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Contact Section */}
      <Container maxWidth="md" sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h3" sx={{ color: '#1e3a8a', fontWeight: 'bold', mb: 3 }}>
          Contact FixtureFix
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
          Have questions about our cricket fixture app? Whether you're a village cricket enthusiast or managing a league, we're here to help. Contact us for support, feedback, or partnerships.
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={() => window.location.href = '/contactus'}
          sx={{
            bgcolor: '#4caf50',
            '&:hover': { bgcolor: '#388e3c' },
            fontSize: '1.2rem',
            padding: '12px 36px',
            fontWeight: 'bold',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'transform 0.2s',
            '&:hover': {
              bgcolor: '#388e3c',
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 8px rgba(0,0,0,0.15)'
            }
          }}
        >
          Contact Us
        </Button>
      </Container>
      <CookieConsent
        location="top"
        buttonText="Accept"
        cookieName="yourCookieName"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "13px",
          borderRadius: "5px",
        }}
        expires={365}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a
          href="/privacy-policy"
          style={{ color: "#f5f5f5", textDecoration: "underline" }}
        >
          Learn more
        </a>
      </CookieConsent>
    </Box>
  );
};

export default Home;