import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import { supabase } from "../Components/supabaseClient";
import useFetchClubs from "../Components/Hooks/useFetchClubs";
import useSendMessage from "../Components/Hooks/useSendMessage";
import { useCheckExistingConversation } from "../Components/Hooks/CheckExistingConversation";

const ChatScreen = () => {
  const [newMessages, setNewMessages] = useState([]);
  const subscriptionRef = useRef(null);
  const { clubName } = useParams();
  const clubs = useFetchClubs();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    messages,
    clickedClubName,
    signedInUserClubName,
    existingConversationId,
    checkExistingConversation,
    listOfConversations,
  } = useCheckExistingConversation();

  const { message, setMessage, sendMessage } = useSendMessage(
    existingConversationId,
    signedInUserClubName
  );

  const filteredClubs = clubs.filter((club) =>
    club.club_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredConversations = listOfConversations.filter((conversation) =>
    (
      conversation.participant1_id === signedInUserClubName
        ? conversation.participant2_id
        : conversation.participant1_id
    )
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (clubName) {
      checkExistingConversation(clubName);
    }
  }, [clubName]);

  useEffect(() => {
    if (!existingConversationId) return;

    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = null;
    }

    subscriptionRef.current = supabase
      .channel(`conversation-${existingConversationId}`)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "messages",
          filter: `conversation_id=eq.${existingConversationId}`,
        },
        (payload) => {
          setNewMessages((prevNewMessages) => [...prevNewMessages, payload.new]);
        }
      )
      .subscribe();

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }
    };
  }, [existingConversationId]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${hours}:${minutes}`;
  };

  const onMessageClick = () => {
    if (message.trim() === "") return;
    sendMessage();
    setMessage("");
  };

  const combinedMessages = [...messages, ...newMessages];

  return (
    <>
      <Header />
      <div style={styles.container}>
        {/* Sidebar */}
        <div style={styles.sidebar}>
          <h3 style={styles.sidebarHeader}>Recent Chats</h3>
          <div style={styles.searchContainer}>
            <input
              type="text"
              placeholder="Search conversations or clubs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={styles.searchInput}
            />
          </div>
          <div style={styles.clubList}>
            {filteredConversations.length > 0 ? (
              filteredConversations.map((conversation) => (
                <div
                  key={conversation.conversation_id}
                  style={{
                    ...styles.club,
                    backgroundColor:
                      conversation.conversation_id === existingConversationId
                        ? "#e8e6ff"
                        : "#fff",
                  }}
                  onClick={() =>
                    checkExistingConversation(
                      conversation.participant1_id === signedInUserClubName
                        ? conversation.participant2_id
                        : conversation.participant1_id
                    )
                  }
                >
                  <strong>
                    {conversation.participant1_id === signedInUserClubName
                      ? conversation.participant2_id
                      : conversation.participant1_id}
                  </strong>
                </div>
              ))
            ) : (
              <p>No conversations found</p>
            )}
          </div>

          <h3 style={styles.sidebarHeader}>All Clubs</h3>
          <div style={styles.clubList}>
            {filteredClubs.length > 0 ? (
              filteredClubs.map((club) => (
                <div
                  key={club.club_uuid}
                  style={styles.club}
                  onClick={() => checkExistingConversation(club.club_name)}
                >
                  {club.club_name}
                </div>
              ))
            ) : (
              <p>No clubs found</p>
            )}
          </div>
        </div>

        {/* Main Chat Area */}
        <div style={styles.chatArea}>
          <h2>{clickedClubName}</h2>
          <div style={styles.messages}>
            {combinedMessages.map((msg, index) => (
              <div
                key={index}
                style={
                  msg.sender_id === signedInUserClubName
                    ? styles.messageRight
                    : styles.messageLeft
                }
              >
                <p>
                  <strong>{msg.sender_id}:</strong> {msg.message_content}
                  <br />
                  <p>{formatDate(msg.created_at)}</p>
                </p>
              </div>
            ))}
          </div>
          <div style={styles.inputContainer}>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              style={styles.input}
            />
            <button onClick={onMessageClick} style={styles.sendButton}>
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  sidebar: {
    width: "25%",
    backgroundColor: "#f4f4f4",
    borderRight: "1px solid #ddd",
    display: "flex",
    flexDirection: "column",
  },
  sidebarHeader: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontSize: "18px",
    fontWeight: "bold",
  },
  clubList: {
    flex: 1,
    overflowY: "auto",
    padding: "10px",
  },
  club: {
    padding: "10px",
    marginBottom: "5px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  chatArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  messages: {
    flex: 1,
    padding: "20px",
    overflowY: "auto",
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  messageLeft: {
    alignSelf: "flex-start",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#e0e0e0",
    borderRadius: "10px",
    maxWidth: "60%",
    wordBreak: "break-word",
  },
  messageRight: {
    alignSelf: "flex-end",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#6558f5",
    color: "#fff",
    borderRadius: "10px",
    maxWidth: "60%",
    wordBreak: "break-word",
  },
  inputContainer: {
    display: "flex",
    padding: "10px 20px",
    borderTop: "1px solid #ddd",
    backgroundColor: "#fff",
  },
  input: {
    flex: 1,
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    marginRight: "10px",
  },
  sendButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#6558f5",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  searchContainer: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  searchInput: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    fontSize: "14px",
  },
};

export default ChatScreen;
