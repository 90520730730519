import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../Components/supabaseClient";
import Map, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Header from './Header';

const AllClubsOnMap = () => {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedClub, setSelectedClub] = useState(null);
  const navigate = useNavigate();
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

  const [viewState, setViewState] = useState({
    latitude: 54.5,
    longitude: -4.0,
    zoom: 5
  });

  const convertPostCode = async (postalCode) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${postalCode}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      
      if (!data.features || data.features.length === 0) {
        throw new Error('No location found for this postal code');
      }
      
      return {
        latitude: data.features[0].center[1],
        longitude: data.features[0].center[0]
      };
    } catch (error) {
      console.error('Error converting postcode:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from("userProfile")
          .select("club_name, club_location");

        if (error) throw error;

        const clubsWithCoordinates = await Promise.all(
          data.map(async (club) => {
            const coordinates = await convertPostCode(club.club_location);
            return {
              ...club,
              coordinates
            };
          })
        );

        setClubs(clubsWithCoordinates.filter(club => club.coordinates));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClubs();
  }, []);

  const handleMapMove = (event) => {
    if (event.viewState) {
      setViewState(event.viewState);
    }
  };

  const handleMarkerClick = (club, event) => {
    event.originalEvent.stopPropagation();
    setSelectedClub(club);
  };

  const handlePopupClose = () => {
    setSelectedClub(null);
  };

  if (loading) return <div>Loading clubs...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Header />
      <div style={{ height: '100vh', width: '100%' }}>
        <Map
          {...viewState}
          onMove={handleMapMove}
          mapboxAccessToken={mapboxToken}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          {clubs.map((club, index) => (
            <Marker
              key={index}
              latitude={club.coordinates.latitude}
              longitude={club.coordinates.longitude}
              onClick={(event) => handleMarkerClick(club, event)}
            >
              <div style={{ 
                cursor: 'pointer',
                color: 'red', 
                fontSize: '24px' 
              }}>
                🏏
              </div>
            </Marker>
          ))}

          {selectedClub && (
            <Popup
              latitude={selectedClub.coordinates.latitude}
              longitude={selectedClub.coordinates.longitude}
              onClose={handlePopupClose}
              closeButton={true}
              closeOnClick={false}
              anchor="bottom"
            >
              <div>
                <h3>{selectedClub.club_name}</h3>
                <p>{selectedClub.club_location}</p>
                <button onClick={() => navigate('/clubprofile', { state: { clubName: selectedClub.club_name } })}>
                  View Profile
                </button>
              </div>
            </Popup>
          )}
        </Map>
      </div>
    </>
  );
};

export default AllClubsOnMap;