import React from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import Header from "./Header";

const SubscriptionScreen = () => {
  return (
   <>
   <Header />
    <Container style={styles.container}>
      <Paper elevation={3} style={styles.paper}>
        <Box style={styles.headerBox}>
          <Typography variant="h3" style={styles.header}>
            Support FixtureFix
          </Typography>
        </Box>
        <Box style={styles.contentBox}>
          <Typography variant="h5" style={styles.subHeader}>
            Servers Cost Money
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            Maintaining FixtureFix requires resources, especially servers, to
            ensure a smooth and reliable experience. By subscribing, you directly
            help keep this app running and evolving.
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            I believe in providing a service that respects your privacy. That’s
            why you’ll never see ads on FixtureFix or have your data sold. Your
            subscription ensures a clean, ad-free environment where your data
            remains yours.
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            FixtureFix connects cricket clubs into a thriving community where you
            can chat, share fixtures, and schedule matches effortlessly. Let’s
            build a platform together that makes finding games seamless and fun!
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            Have questions or feedback? Feel free to{" "}
            <a href="/contactus" style={styles.link}>
              contact me
            </a>.
          </Typography>
          <Typography variant="h6" style={styles.benefitsHeader}>
            Subscription Benefits:
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            - Full access to all features of FixtureFix.
            <br />
            - Contribution to keeping FixtureFix ad-free and private.
            <br />
            - Support the continued development of new features and updates.
          </Typography>
          <Typography variant="h6" style={styles.cost}>
            Subscription Cost: £100/year per club
          </Typography>
          <Box style={styles.buttonBox}>
          </Box>
        </Box>
      </Paper>
    </Container>
   </>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  paper: {
    padding: "30px",
    borderRadius: "12px",
    maxWidth: "800px",
    width: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  headerBox: {
    marginBottom: "20px",
  },
  header: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#4a90e2",
  },
  subHeader: {
    textAlign: "center",
    fontWeight: "500",
    color: "#333",
    marginBottom: "15px",
  },
  bodyText: {
    textAlign: "center",
    color: "#555",
    lineHeight: "1.6",
    marginBottom: "15px",
    fontSize: "16px",
  },
  link: {
    color: "#4a90e2",
    textDecoration: "none",
    fontWeight: "500",
  },
  benefitsHeader: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    marginTop: "20px",
  },
  cost: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#e74c3c",
    marginTop: "10px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  subscribeButton: {
    backgroundColor: "#4a90e2",
    color: "#ffffff",
    padding: "10px 20px",
    borderRadius: "25px",
    fontSize: "16px",
    textTransform: "none",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#357ab7",
    },
  },
};

export default SubscriptionScreen;
