import React, { useState, useEffect } from "react";
import { supabase } from "../Components/supabaseClient";
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  TextField,
  Box,
  Divider,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

const PAGE_SIZE = 10; // Number of items per page

const ClubList = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const getClubList = async () => {
      const { data, error } = await supabase
        .from("userProfile")
        .select("id, club_name, club_description");
      if (error) {
        console.error("Error fetching club list:", error);
      } else {
        setData(data);
        console.log(data);
      }
    };
    getClubList();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const viewProfile = (clubName) => {
    navigate("/clubProfile", {
      state: { clubName },
    });
  };

  // Filter and group clubs
  const filteredClubs = data.filter((club) =>
    club.club_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const groupedClubs = filteredClubs.reduce((groups, club) => {
    const letter = club.club_name[0].toUpperCase(); // Get the first letter
    if (!groups[letter]) {
      groups[letter] = [];
    }
    groups[letter].push(club);
    return groups;
  }, {});

  const sortedLetters = Object.keys(groupedClubs).sort(); // Sort letters alphabetically

  // Pagination Logic
  const paginatedLetters = sortedLetters.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const totalPages = Math.ceil(sortedLetters.length / PAGE_SIZE);

  return (
    <>
      <Header />
      <Container sx={styles.container}>
        <Typography variant="h4" align="center" gutterBottom sx={styles.header}>
          All Clubs
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Search clubs..."
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          sx={styles.searchField}
        />
        <Box sx={styles.listContainer}>
          <List>
            {paginatedLetters.length > 0 ? (
              paginatedLetters.map((letter) => (
                <React.Fragment key={letter}>
                  {/* Letter Header */}
                  <Typography variant="h6" sx={styles.letterHeader}>
                    {letter}
                  </Typography>
                  <Divider />
                  {/* Clubs under the letter */}
                  {groupedClubs[letter].map((club) => (
                    <React.Fragment key={club.id}>
                      <ListItem
                        button
                        onClick={() => viewProfile(club.club_name)}
                        sx={styles.listItem}
                      >
                        <ListItemText
                          primary={club.club_name}
                          secondary={club.club_description}
                          primaryTypographyProps={{ sx: styles.listItemText }}
                          secondaryTypographyProps={{
                            sx: styles.listItemDescription,
                          }}
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body1" align="center" sx={styles.noResults}>
                No clubs found.
              </Typography>
            )}
          </List>
        </Box>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <Box sx={styles.paginationContainer}>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              sx={styles.paginationButton}
            >
              Previous
            </Button>
            <Typography variant="body1" sx={styles.paginationText}>
              Page {currentPage} of {totalPages}
            </Typography>
            <Button
              variant="contained"
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              sx={styles.paginationButton}
            >
              Next
            </Button>
          </Box>
        )}
      </Container>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
  },
  header: {
    marginBottom: "20px",
    fontWeight: "bold",
    color: "#333",
  },
  searchField: {
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
  listContainer: {
    width: "100%",
    maxWidth: 600,
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
  },
  letterHeader: {
    padding: "10px 20px",
    fontWeight: "bold",
    color: "#444",
    backgroundColor: "#f0f0f0",
  },
  listItem: {
    padding: "15px 20px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  listItemText: {
    fontSize: "18px",
    color: "#333",
  },
  listItemDescription: {
    fontSize: "14px",
    color: "#777",
  },
  noResults: {
    padding: "20px",
    color: "#888",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    width: "100%",
    maxWidth: 600,
  },
  paginationButton: {
    minWidth: "100px",
  },
  paginationText: {
    fontWeight: "bold",
    color: "#333",
  },
};

export default ClubList;
