import React from "react";
import { Typography, Box, Container } from "@mui/material";
import Header from './Header';

const Terms = () => {
  return (
    <>
      <Header />
      <Container>
        <Typography variant="h3" align="center" gutterBottom>
          Terms and Conditions
        </Typography>

        <Box sx={{ width: "75%", mx: "auto" }}>
          <Typography variant="body1" gutterBottom>
            Welcome to FixtureFix!
          </Typography>
          <Typography variant="body1" gutterBottom>
            These terms and conditions outline the rules and regulations for the use of FixtureFix's Website, located at www.fixturefix.co.uk.
          </Typography>
          <Typography variant="body1" gutterBottom>
            By accessing this website we assume you accept these terms and conditions. Do not continue to use FixtureFix if you do not agree to take all of the terms and conditions stated on this page.
          </Typography>
          <Typography variant="h4" gutterBottom>
            Cookies
          </Typography>
          <Typography variant="body1" gutterBottom>
            We employ the use of cookies. By accessing FixtureFix, you agreed to use cookies in agreement with the FixtureFix's Privacy Policy.
          </Typography>
          <Typography variant="h4" gutterBottom>
            License
          </Typography>
          <Typography variant="body1" gutterBottom>
            Unless otherwise stated, FixtureFix and/or its licensors own the intellectual property rights for all material on FixtureFix. All intellectual property rights are reserved. You may access this from FixtureFix for your own personal use subjected to restrictions set in these terms and conditions.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You must not:
          </Typography>
          <ul>
            <li>Republish material from FixtureFix</li>
            <li>Sell, rent or sub-license material from FixtureFix</li>
            <li>Reproduce, duplicate or copy material from FixtureFix</li>
            <li>Redistribute content from FixtureFix</li>
          </ul>
        </Box>
      </Container>
    </>
  );
};

export default Terms;
