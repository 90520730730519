import React from "react";
import { Button, Input } from "@mui/material";

const DeleteProfile = () => {
  const deleteProfile = async () => {
    console.log("Profile deleted");
    window.location.href = "/";
  };

  

  return (
    <div>
      <h1>Delete Profile</h1>
      <p>Are you sure you want to delete your profile?</p>
      <Input
        type="email"
        placeholder="Enter your email"
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: "1rem" }}
        onClick={deleteProfile}
      >
        Delete Profile
      </Button>
    </div>
  );
};

export default DeleteProfile;
