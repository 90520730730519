import React, { useCallback, useState, useEffect } from "react";
import { postcodeValidator } from "postcode-validator";
import { supabase } from "../Components/supabaseClient";
import { useLocation } from "react-router-dom";
import generateSecureRandomCode from "../Components/Hooks/generateRandomCode";
import "../Components/Styles/LoginAfterSigningUp.css";

const LoginAfterSigningUp = () => {
  const location = useLocation();
  const [venueLocation, setVenueLocation] = useState("");
  const [clubName, setClubName] = useState("");
  const [randomCode, setRandomCode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [logo, setLogo] = useState("");
  const [whatThreeWords, setWhatThreeWords] = useState("");
  const [id, setId] = useState("");

  const signUserIn = useCallback(async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: location.state.email,
      password: location.state.password,
    });
    error ? alert("Error logging in: " + error.message) : null;
  }, [location.state.email, location.state.password]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) throw error;
        setId(data.user.id);
        await supabase.auth.refreshSession();
        signUserIn();
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, [signUserIn]);

  const postcodeverify = async (e) => {
    e.preventDefault();
    const isValid = postcodeValidator(venueLocation, "GB");
    if (isValid) {
      populateUserProfile();
    } else {
      alert("Please enter a valid postcode: In this format SW11 1AA");
    }
  };

  const populateUserProfile = async () => {
    const length = 4;
    const fcmToken = generateSecureRandomCode(length);
    try {
      const { error } = await supabase
        .from("userProfile")
        .insert([
          {
            club_location: venueLocation,
            club_email: email,
            club_name: clubName,
            club_photo: logo,
            user_profile_icon_code: randomCode,
            club_photo: base64Image,
            contact_number: contact,
            club_uuid: id,
            fcm_token: fcmToken,
          },
        ])
        .select();
      if (error) {
        console.error("Error adding user profile:", error);
      } else {
        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.error("Error adding user profile:", error);
      alert("Error", error);
    }
  };

  const uploadImage = async (e) => {
    // Get the file from the event
    const file = e.target.files[0];
    
    if (!file) {
      console.error("No file selected");
      return;
    }
  
    try {
      // Convert to base64
      const base64Data = await fileToBase64(file);
      
      // Format the image data to match mobile app structure
      const imageData = JSON.stringify({
        assets: [{
          base64: base64Data
        }]
      });
      console.log("Image data:", imageData);
      // Update state with the formatted image data
      setLogo(imageData);
      setBase64Image(imageData)
      console.log("Image uploaded successfully", imageData);
      return { 
        success: true, 
        data: imageData 
      };
    } catch (err) {
      console.error("Error in image upload:", err);
      throw err;
    }
  };
  
  // Helper function to convert File to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error('Input must be a File or Blob'));
        return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Remove the data:image/jpeg;base64, prefix
        const base64 = reader.result.toString().split(',')[1];
        resolve(base64);
      };
      reader.onerror = (error) => reject(error);
    });
  };
  return (
    <div className="login-after-signing-up">
      <h1>User Profile</h1>
      <div className="user-profile">
        <form className="user-profile-form" onSubmit={postcodeverify}>
          <input
            className="input"
            type="text"
            placeholder="Club Name"
            value={clubName}
            onChange={(e) => setClubName(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Location (e.g., SW18 4RD)"
            value={venueLocation}
            onChange={(e) => setVenueLocation(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Contact"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
          <textarea
            className="input"
            placeholder="Club Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder="What Three Words : e.g., index.home.raft"
            value={whatThreeWords}
            onChange={(e) => setWhatThreeWords(e.target.value)}
          />
          <input
            className="input"
            type="file"
            accept="image/*"
            onChange={uploadImage}
          />
          <input
            className="input"
            type="text"
            placeholder="Club Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <button className="button" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginAfterSigningUp;
