export const Colors = {
  /** transparent: transparent */
  transparent: "transparent",

  /** white: #FFFFFF */
  white: "#FFFFFF",

  /** black: #171B1B */
  black: "#171B1B",
  /** black95: #232727 */
  black95: "#232727",
  /** black90: #2E3232 */
  black90: "#2E3232",
  /** black80: #454949 */
  black80: "#454949",
  /** black60: #747676 */
  black60: "#747676",
  /** black50: #979797 */
  black50: "#979797",
  /** black40: #A2A4A4 */
  black40: "#A2A4A4",
  /** black30: #B9BBBB */
  black30: "#B9BBBB",
  /** black20: #D1D1D1 */
  black20: "#D1D1D1",
  /** black10: #E7E8E8 */
  black10: "#E7E8E8",
  /** black05: #F3F3F3 */
  black05: "#F3F3F3",
  /** black03: #F8F8F8 */
  black03: "#F8F8F8",

  /** pinkDark: #2E2426 */
  pinkDark: "#2E2426",
  /** pink: #F45F78 */
  pink: "#F45F78",
  /** pink80: #F67F93 */
  pink80: "#F67F93",
  /** pink60: #F89FAE */
  pink60: "#F89FAE",
  /** pink50: #F9AFBB */
  pink50: "#F9AFBB",
  /** pink40: #FBBFC9 */
  pink40: "#FBBFC9",
  /** pink20: #FDDFE4 */
  pink20: "#FDDFE4",
  /** pink10: #FEEFF2 */
  pink10: "#FEEFF2",
  /** pink08: #FEF2F4 */
  pink08: "#FEF2F4",

  /** blue: #64D2DC */
  blue: "#64D2DC",
  /** blue80: #1e3a8a */
  blue80: "#1e3a8a",
  /** blue60: #A2E4EA */
  blue60: "#A2E4EA",
  /** blue50: #B1E8ED */
  blue50: "#B1E8ED",
  /** blue40: #C1EDF1 */
  blue40: "#C1EDF1",
  /** blue20: #E0F6F8 */
  blue20: "#E0F6F8",
  /** blue10: #EFFAFB */
  blue10: "#EFFAFB",
  /** blue08: #F3FCFD */
  blue08: "#F3FCFD",

  /** mindaro: #C0F367 */
  mindaro: "#C0F367",

  /** riptide: #86E1D1 */
  riptide: "#86E1D1",

  /** jordyBlue: #3B74D3 */
  jordyBlue: "#3B74D3",
  /** jordyBlue50: #9DB9E9 */
  jordyBlue50: "#9DB9E9",
  /** jordyBlue10: #EBF1FA */
  jordyBlue10: "#EBF1FA",

  /** purple: #6B51C9 */
  purple: "#6B51C9",
  /** purple50: #B5A8E4 */
  purple50: "#B5A8E4",
  /** purple10: #F0EDF9 */
  purple10: "#F0EDF9",

  /** violet: #A94ECA */
  violet: "#A94ECA",
  /** violet50: #D4A6E4 */
  violet50: "#D4A6E4",
  /** violet10: #F6EDF9 */
  violet10: "#F6EDF9",

  /** paleViolet: #C33C79 */
  paleViolet: "#C33C79",
  /** paleViolet50: #E19DBC */
  paleViolet50: "#E19DBC",
  /** paleViolet10: #F9EBF1 */
  paleViolet10: "#F9EBF1",

  /** cobaltBlue: #364DBF */
  cobaltBlue: "#364DBF",
  /** cobaltBlue50: #  */
  cobaltBlue50: "#9AA6DF",
  /** cobaltBlue10: #EAEDF8 */
  cobaltBlue10: "#EAEDF8",

  /** lightBlueMagenta: #F5F5F7 */
  lightBlueMagenta: "#F5F5F7",

  /** teal: #1E7C92 */
  teal: "#1E7C92",
  /** teal50: #8EBDC8 */
  teal50: "#8EBDC8",
  /** teal10: #E8F1F4 */
  teal10: "#E8F1F4",

  /** red: #C81F4B */
  red: "#C81F4B",
  /** red10: #FAE9ED */
  red10: "#FAE9ED",

  /** amber: #FFA513 */
  amber: "#FFA513",
  /** amber80: #FFB742 */
  amber80: "#FFB742",
  /** amber10: #FFF6E7 */
  amber10: "#FFF6E7",

  /** green: #1FC8A9 */
  green: "#1FC8A9",
  /** green10: #E9FAF6 */
  green10: "#E9FAF6",
};

// export default color;
