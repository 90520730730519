import React from "react";
import { Button, Container, Typography, Box, Divider } from "@mui/material";
import { Colors } from "../Components/Theme/Colors";
import Header from "./Header";

const AboutUs = () => {
  const handleNavigation = () => {
    window.location.href = "/contactUs";
  };

  return (
    <>
      <Header />
      <Container maxWidth="md" style={styles.container}>
        <Typography variant="h3" style={styles.header}>
          About FixtureFix
        </Typography>
        <Divider style={styles.divider} />
        <Box style={styles.contentBox}>
          <Typography variant="body1" style={styles.bodyText}>
            FixtureFix is designed to revolutionize how cricket teams organize fixtures. 
            As a passionate cricketer with over a decade of experience, I understand the 
            challenges fixture secretaries face when scheduling matches. Traditional 
            methods often lead to missed opportunities and inefficiencies.
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            With FixtureFix, I aim to simplify the process, providing a seamless and 
            intuitive platform for teams to connect, schedule, and play more matches. 
            My mission is to bring clarity and convenience to cricket scheduling, so 
            players can focus on the game they love.
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            FixtureFix isn't just a tool; it's a solution born out of passion, built for 
            the cricketing community.  FixtureFix is here to make your cricketing life easier.
          </Typography>
          <Typography variant="body1" style={styles.bodyText}>
            Your feedback is invaluable to me. If you have any suggestions or ideas, 
            please reach out using the contact page. Together, we can make FixtureFix 
            the ultimate cricket scheduling platform.
          </Typography>
          <Box style={styles.buttonBox}>
            <Button
              variant="contained"
              style={styles.button}
              onClick={handleNavigation}
            >
              Contact Us
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
    backgroundColor: Colors.white,
  },
  header: {
    marginBottom: "10px",
    color: Colors.blue80,
    fontWeight: "bold",
    textAlign: "center",
  },
  divider: {
    width: "80%",
    margin: "10px auto 20px",
    backgroundColor: Colors.black,
  },
  contentBox: {
    textAlign: "center",
    width: "100%",
    padding: "20px",
    boxShadow: "0px 4px 6px rgba(21, 19, 171, 0.1)",
    borderRadius: "12px",
    backgroundColor: Colors.white,
  },
  bodyText: {
    marginBottom: "15px",
    color: Colors.black,
    lineHeight: "1.8",
    fontSize: "18px",
  },
  buttonBox: {
    marginTop: "30px",
  },
  button: {
    backgroundColor: Colors.pink,
    color: Colors.white,
    padding: "12px 24px",
    borderRadius: "25px",
    fontSize: "16px",
    textTransform: "none",
    transition: "background-color 0.3s ease",
  },
};

export default AboutUs;
