import React, { useState } from "react";
import { supabase } from "../Components/supabaseClient";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import Header from './Header';

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const loginUser = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    if (error) {
      alert("Error logging in: " + error.message);
    } else {
      window.location.href = "/dashboard";
    }
  };

  return (
   <>
   <Header />
   <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      style={{
        background: "linear-gradient(135deg, #BFD9FF, #8CBBFE)",
      }}
    >
      <Container maxWidth="xs">
        <Card style={{ padding: "2rem", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" gutterBottom style={{ fontWeight: "bold" }}>
                Login
              </Typography>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="dense"
                sx={{ marginBottom: 2 }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="dense"
                sx={{ marginBottom: 2 }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  marginTop: "1rem",
                  borderRadius: "25px",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
                onClick={loginUser}
              >
                Login
              </Button>
              <Typography variant="body2" style={{ marginTop: "1rem", color: "#6b6b6b" }}>
                Don't have an account?{" "}
                <Link href="/signup" underline="hover" style={{ color: "#6558f5" }}>
                  Sign Up
                </Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Typography
          variant="body2"
          style={{
            marginTop: "2rem",
            textAlign: "center",
            color: "#000B0C",
          }}
        >
          © 2025 FixtureFix. All Rights Reserved.
        </Typography>
      </Container>
    </Box>
   </>
  );
};

export default Login;
