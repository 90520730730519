import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Card,
  CardContent
} from '@mui/material';
import { supabase } from '../Components/supabaseClient';
import { useNavigate } from 'react-router-dom';

const AddFixture = () => {
  const navigate = useNavigate();
  const [clubName, setClubName] = useState('');
  const [formData, setFormData] = useState({
    fixture_team_name: '',
    fixture_date: '',
    fixture_email: '',
    fixture_venue: '',
    fixture_description: '',
    max_travel_radius: '',
    fixture_postcode: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [clubUuid, setClubUuid] = useState('');

  useEffect(() => {
    const getUser = async () => {
      try {
        const {
          data: { user },
          error,
        } = await supabase.auth.getUser();
        if (error) throw error;
        setClubUuid(user.id);

        // Fetch club name from userProfile
        const { data, error: profileError } = await supabase
          .from('userProfile')
          .select('club_name')
          .eq('club_uuid', user.id)
          .single();

        if (profileError) throw profileError;
        if (data) {
          setClubName(data.club_name);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Failed to get user information');
      }
    };
    getUser();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { error: supabaseError } = await supabase.from('fixture').insert([
        {
          club_name: clubName,
          fixture_team_name: formData.fixture_team_name,
          fixture_date: formData.fixture_date, // Pass date as-is
          fixture_email: formData.fixture_email,
          fixture_venue: formData.fixture_venue,
          club_uuid: clubUuid,
          fixture_description: formData.fixture_description,
          max_travel_radius: parseInt(formData.max_travel_radius),
          fixture_postcode: formData.fixture_postcode,
        },
      ]);

      if (supabaseError) throw supabaseError;

      navigate('/dashboard');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Add New Fixture
        </Typography>

        <Card>
          <CardContent>
            <Box mb={3}>
              <Typography variant="h6" color="textSecondary">
                Club: {clubName}
              </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <TextField
                  fullWidth
                  label="Team Name"
                  name="fixture_team_name"
                  value={formData.fixture_team_name}
                  onChange={handleChange}
                  required
                />
              </Box>

              <Box mb={3}>
                <TextField
                  fullWidth
                  type="date"
                  label="Fixture Date"
                  name="fixture_date"
                  value={formData.fixture_date} // Bound to state
                  onChange={handleChange} // Updates state when date is selected
                  InputLabelProps={{
                    shrink: true, // Keeps label above the input
                  }}
                  required
                />
              </Box>

              <Box mb={3}>
                <TextField
                  fullWidth
                  type="email"
                  label="Contact Email"
                  name="fixture_email"
                  value={formData.fixture_email}
                  onChange={handleChange}
                  required
                />
              </Box>

              <Box mb={3}>
                <TextField
                  fullWidth
                  label="Venue"
                  name="fixture_venue"
                  value={formData.fixture_venue}
                  onChange={handleChange}
                  required
                />
              </Box>

              <Box mb={3}>
                <TextField
                  fullWidth
                  label="Postcode"
                  name="fixture_postcode"
                  value={formData.fixture_postcode}
                  onChange={handleChange}
                  required
                />
              </Box>

              <Box mb={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Maximum Travel Radius (km)"
                  name="max_travel_radius"
                  value={formData.max_travel_radius}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  required
                />
              </Box>

              <Box mb={3}>
                <TextField
                  fullWidth
                  label="Description"
                  name="fixture_description"
                  value={formData.fixture_description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Box>

              {error && (
                <Box mb={2}>
                  <Typography color="error">{error}</Typography>
                </Box>
              )}

              <Box display="flex" justifyContent="space-between">
                <Button variant="outlined" onClick={() => navigate('/dashboard')}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  style={{ backgroundColor: '#6558f5' }}
                >
                  {loading ? 'Adding...' : 'Add Fixture'}
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default AddFixture;