import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, TextField, Button, Container } from "@mui/material";

const OtpVerify = () => {
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const verifyOtp = () => {
    const { randomCode } = location.state || {};
    otp === randomCode?.toString() ? signUserUp() : alert("Incorrect OTP. Please try again.");
  };

  const signUserUp = () => {
    navigate("/loginaftersigningup", {
      state: {
        email: location.state?.email,
        password: location.state?.password,
      },
    });
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h4" gutterBottom>
          OTP Verification
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
          Please enter the OTP sent to your registered email.
        </Typography>
        <TextField
          label="Enter OTP"
          variant="outlined"
          fullWidth
          margin="normal"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          sx={{ maxWidth: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={verifyOtp}
          sx={{ mt: 2, width: "300px" }}
        >
          Verify OTP
        </Button>
      </Box>
    </Container>
  );
};

export default OtpVerify;