import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../Components/supabaseClient";
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Header from './Header';

const ClubLocation = () => {
  const [postalCode, setPostalCode] = useState("");
  const [club, setClub] = useState("");
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const [viewState, setViewState] = useState({
    latitude: 51.50101009999999,
    longitude: -0.1415627,
    zoom: 17
  });

  const [markerPosition, setMarkerPosition] = useState({
    latitude: 51.50101009999999,
    longitude: -0.1415627
  });
  const location = useLocation();

  const handleMapMove = (event) => {
    if (event.viewState) {
      setViewState(event.viewState);
    }
  };

  const convertPostCode = async (postalCode) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${postalCode}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();

      if (!data.features || data.features.length === 0) {
        throw new Error('No location found for this postal code');
      }

      return {
        latitude: data.features[0].center[1],
        longitude: data.features[0].center[0]
      };
    } catch (error) {
      console.error('Error converting postcode:', error);
      return null;
    }
  };

  useEffect(() => {
    const { clubName } = location.state || {};
    setClub(clubName || "Default Club Name");
  }, [location.state]);

  useEffect(() => {
    if (club) {
      const getLocation = async () => {
        const { data, error } = await supabase
          .from("userProfile")
          .select("*")
          .eq("club_name", club);
        if (data && data.length > 0) {
          setPostalCode(data[0].club_location);
        }
      };
      getLocation();
    }
  }, [club]);

  useEffect(() => {
    if (postalCode) {
      convertPostCode(postalCode).then((location) => {
        if (location) {
          setMarkerPosition({
            latitude: location.latitude,
            longitude: location.longitude
          });
          setViewState(prev => ({
            ...prev,
            latitude: location.latitude,
            longitude: location.longitude
          }));
        }
      });
    }
  }, [postalCode]);


  return (
    <>
      <Header />
      <div style={{ height: '100vh', width: '100%' }}>
        <h1>Club Location</h1>
        <div style={{ height: '80%', width: '100%' }}>
          <Map
            {...viewState}
            onMove={handleMapMove}
            mapboxAccessToken={mapboxToken}
            mapStyle="mapbox://styles/mapbox/streets-v11"
          >
            <Marker
              latitude={markerPosition.latitude}
              longitude={markerPosition.longitude}
            >
              <div style={{ color: 'red', fontSize: '24px' }}>🏏</div>
            </Marker>
          </Map>
        </div>
      </div>
    </>
  );
};

export default ClubLocation;