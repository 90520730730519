import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@mui/material";
import { supabase } from "../Components/supabaseClient";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import { profile } from "../Components/Redux/userProfile";
import "../App.css";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import Header from './Header';
import useClubImage from "../Components/Hooks/userImageHook";

const Dashboard = () => {
  const [userId, setUserId] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fixtures, setFixtures] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState('');
  const [filterClub, setFilterClub] = useState('');
  const [filterHomeAway, setFilterHomeAway] = useState('');
  const [filterDistance, setFilterDistance] = useState('');
  const { clubPhotoBase64, clubName } = useClubImage(userId);

  const handleFilterChange = (status) => {
    setFilterStatus(status);
    getAllFixtures(selectedDate, { status, homeAway: filterHomeAway, distance: filterDistance });
  };
  
  const handleClubFilterChange = (clubName) => {
    setFilterClub(clubName);
    getAllFixtures(selectedDate, { clubName, homeAway: filterHomeAway, distance: filterDistance });
  };

  const handleHomeAwayChange = (value) => {
    setFilterHomeAway(value);
    getAllFixtures(selectedDate, { status: filterStatus, homeAway: value, distance: filterDistance });
  };

  const handleDistanceChange = (value) => {
    setFilterDistance(value);
    getAllFixtures(selectedDate, { status: filterStatus, homeAway: filterHomeAway, distance: value });
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) throw error;
        setUserId(data.user.id);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!userId) return;
      try {
        const { data, error } = await supabase
          .from("userProfile")
          .select("*")
          .eq("club_uuid", userId);
        if (error) throw error;
        if (data.length > 0) {
          const userProfile = data[0];
          const clubPhotoBase64 = JSON.parse(userProfile.club_photo).assets[0].base64;
          dispatch(
            profile(
              userProfile.email,
              userProfile.club_name,
              clubPhotoBase64,
              userProfile.club_address,
              userProfile.club_phone
            )
          );
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserInfo();
  }, [userId, dispatch]);

  const getAllFixtures = useCallback(async (date, filters = {}) => {
    setSelectedDate(date);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    
    let query = supabase
      .from("fixture")
      .select("*")
      .eq("fixture_date", formattedDate);
  
    if (filters.status) {
      query = query.eq("fixture_status", filters.status);
    }
    if (filters.clubName) {
      query = query.ilike("club_name", `%${filters.clubName}%`);
    }
    if (filters.homeAway) {
      query = query.eq("fixture_type", filters.homeAway);
    }
    if (filters.distance) {
      query = query.lte("distance", parseInt(filters.distance));
    }
    try {
      const { data, error } = await query;
      if (error) throw error;
      setFixtures(data);
      console.log("fixtures", data);
    } catch (error) {
      console.error("Error fetching fixtures:", error);
    }
  }, []);

  useEffect(() => {
    getAllFixtures(selectedDate);
  }, [selectedDate, getAllFixtures]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const sendClubName = (clubName) => {
    navigate("/clubprofile", {
      state: {
        clubName: clubName,
      },
    });
  };

  const addFixture = () => {
    navigate("/addfixture");
  };

  return (
    <>
      <Header />
      <Container maxWidth="md" style={{ fontFamily: "Roboto, sans-serif" }}>
        {clubPhotoBase64 ? (
          <Box display="flex" justifyContent="center">
            <img
              src={`data:image/jpeg;base64,${clubPhotoBase64}`}
              alt="club"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                objectFit: "cover",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        ) : (
          <Box
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              backgroundColor: "#6558f5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h3" style={{ color: "white" }}>
              {clubName.charAt(0)}
            </Typography>
          </Box>
        )}
        
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          style={{ fontWeight: "bold", marginTop: "20px", color: "#4a4a4a" }}
          onClick={() => sendClubName(clubName)}
        >
          {clubName}
        </Typography>

        <Box display="flex" justifyContent="center" marginBottom={3}>
          <Calendar
            className="custom-calendar"
            value={selectedDate}
            onChange={handleDateChange}
            tileClassName="calendar-tile"
          />
        </Box>

        <List>
          {fixtures.map((fixture, index) => (
            <ListItem
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Card
                variant="outlined"
                style={{
                  width: "50%",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                }}
              >
                <CardContent>
                  <Typography 
                    className="touchable-team-name" 
                    variant="h6" 
                    style={{ color: "#6558f5", cursor: "pointer" }} 
                    onClick={() => sendClubName(fixture.club_name)}
                  >
                    {fixture.club_name}
                  </Typography>
                  <Typography><strong>Fixture Date: </strong>{fixture.fixture_date}</Typography>
                  <Typography style={{ color: "black" }}>
                    <strong>Team Name:</strong> {fixture.fixture_team_name}
                  </Typography>
                  <Typography><strong>Venue:</strong> {fixture.fixture_venue || "Not specified"}</Typography>
                  <Typography>
                    <strong>Distance:</strong> {fixture.max_travel_radius ? `${fixture.max_travel_radius}km` : "Not specified"}
                  </Typography>
                  <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      <strong>Description:</strong> {fixture.fixture_description || "No description provided."}
                  </Typography>
                  {fixture.fixture_status && (
                    <Typography style={{ color: "red" }}>
                      Fixture Status: {fixture.fixture_status}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>

        <Box display="flex" justifyContent="center" marginTop={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={addFixture}
            style={{ backgroundColor: "#6558f5", color: "white" }}
          >
            Add Fixture
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Dashboard;