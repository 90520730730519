import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import generateSecureRandomCode from "./generateRandomCode";

export const useCheckExistingConversation = () => {
  const [messages, setMessages] = useState([]);
  const [clickedClubName, setClickedClubName] = useState("");
  const [signedInUserClubName, setSignedInUserClubName] = useState("");
  const [existingConversationId, setExistingConversationId] = useState("");
  const [listOfConversations, setListOfConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchConversations = async () => {
    try {
      const { data: authData, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;

      const { data: userProfileData, error: userProfileError } = await supabase
        .from("userProfile")
        .select("club_name")
        .eq("club_uuid", authData.user.id)
        .single();
      if (userProfileError) throw userProfileError;

      const userClubName = userProfileData.club_name;
      setSignedInUserClubName(userClubName);

      const { data: conversationData, error: conversationError } = await supabase
        .from("conversations")
        .select("*")
        .or(
          `participant1_id.eq."${userClubName}",participant2_id.eq."${userClubName}"`
        );
      if (conversationError) throw conversationError;

      const conversationsWithMessages = await Promise.all(
        conversationData.map(async (conversation) => {
          const { data: messages } = await supabase
            .from("messages")
            .select("*")
            .eq("conversation_id", conversation.conversation_id)
            .order("created_at", { ascending: true });
          
          return {
            ...conversation,
            messages: messages || []
          };
        })
      );

      setListOfConversations(conversationsWithMessages);
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  const checkExistingConversation = async (clubName) => {
    setLoading(true);
    setClickedClubName(clubName);
    try {
      const { data: authData, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;

      const { data: userProfileData, error: userProfileError } = await supabase
        .from("userProfile")
        .select("club_name")
        .eq("club_uuid", authData.user.id)
        .single();
      if (userProfileError) throw userProfileError;
      
      const signedInUserClubName = userProfileData.club_name;
      setSignedInUserClubName(signedInUserClubName);

      const existingConversation = listOfConversations.find(
        (convo) =>
          (convo.participant1_id === signedInUserClubName &&
            convo.participant2_id === clubName) ||
          (convo.participant1_id === clubName &&
            convo.participant2_id === signedInUserClubName)
      );

      if (existingConversation) {
        setMessages(existingConversation.messages);
        setExistingConversationId(existingConversation.conversation_id);
      } else {
        const convoId = generateSecureRandomCode(4);
        const { error: newConvoError } = await supabase
          .from("conversations")
          .insert([
            {
              conversation_id: convoId,
              participant1_id: signedInUserClubName,
              participant2_id: clubName,
            },
          ]);
        if (newConvoError) throw newConvoError;
        setExistingConversationId(convoId);
        setMessages([]);
        await fetchConversations(); // Refresh conversations list
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    messages,
    clickedClubName,
    signedInUserClubName,
    existingConversationId,
    checkExistingConversation,
    listOfConversations,
    loading,
    error,
    fetchConversations
  };
};