import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

const useFetchClubs = () => {
  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    const fetchClubs = async () => {
      const { data, error } = await supabase.from("userProfile").select("id, club_name, club_description");
      error ? console.log(error) : setClubs(data);
    };
    fetchClubs();
  }, []);

  return clubs;
};

export default useFetchClubs;
