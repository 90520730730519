import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../Components/supabaseClient";
import { useNavigate } from "react-router-dom";
import "../Components/Styles/ClubProfile.css";
import { Button } from "@mui/material";
import Header from './Header';

const ClubProfile = () => {
  const location = useLocation();
  const [fixtures, setFixtures] = useState([]);
  const [clubInfo, setClubInfo] = useState({
    clubName: "",
    clubLocation: "",
    clubContact: "",
    clubEmail: "",
    clubWebsite: "",
    clubDescription: "",
    clubFixtureList: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state && location.state.clubName) {
      setClubInfo((prevState) => ({
        ...prevState,
        clubName: location.state.clubName,
      }));
      getClubInfo(location.state.clubName);
    }
    //eslint-disable-next-line
  }, []);

  const getClubInfo = async (clubName) => {
    try {
      const response = await fetchClubDataFromSupabase(clubName);
      setClubInfo((prevState) => ({
        ...prevState,
        clubName: response.club_name,
        clubLocation: response.club_location,
        clubContact: response.contact_number,
        clubEmail: response.club_email,
        clubWebsite: response.website,
        clubDescription: response.description,
        clubFixtureList: response.fixtureList,
      }));

      try {
        const { data, error } = await supabase
          .from("fixture")
          .select("*")
          .eq("club_name", clubName);
        if (error) throw error;
        setFixtures(data);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    } catch (error) {
      console.error("Error fetching club info:", error);
    }
  };

  const fetchClubDataFromSupabase = async (clubName) => {
    try {
      const { data, error } = await supabase
        .from("userProfile")
        .select("*")
        .eq("club_name", clubName)
        .single();
      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  };
  const goToMapScreen = () => {
    navigate("/clublocation", {
      state: { clubName: location.state.clubName },
    });
  };
  return (
   <>
   <Header />
   <div className="club-profile-container">
      <div className="club-info-row">
        <div className="club-info-item">
          <h2>Club Name</h2>
          <p>{clubInfo.clubName}</p>
        </div>
        <div className="club-info-item">
          <h2>Club Location</h2>
          <p>{clubInfo.clubLocation}</p>
          <Button variant="contained" color="primary" onClick={goToMapScreen}>
            View on a map
          </Button>
        </div>
      </div>
      <div className="club-info-row">
        <div className="club-info-item">
          <h2>Club Contact</h2>
          <p>{clubInfo.clubContact}</p>
        </div>
        <div className="club-info-item">
          <h2>Club Email</h2>
          <p>{clubInfo.clubEmail}</p>
        </div>
      </div>
      <div className="fixtures">
        <h2>Fixtures</h2>
        <ul>
          {fixtures.map((fixture) => (
            <li key={fixture.id} className="fixture-item">
              <p className="fixture-club-name">{fixture.club_name}</p>
              <p className="fixture-date">{fixture.fixture_date}</p>
              <p className="fixture-date">{fixture.fixture_venue}</p>
              <p className="fixture-description">
                {fixture.fixture_description}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
   </>
  );
};

export default ClubProfile;
