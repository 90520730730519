import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logo from "../Components/Images/logo.png";
import { Colors } from "../Components/Theme/Colors";

const HeaderComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Login", action: () => (window.location.href = "/login") },
    { label: "Sign Up", action: () => (window.location.href = "/signup") },
    { label: "About Us", action: () => (window.location.href = "/aboutUs") },
    { label: "Chat", action: () => (window.location.href = "/chatscreen") },
    { label: "All Clubs", action: () => (window.location.href = "/clubList") },
    { label: "Map Of All Clubs", action: () => (window.location.href = "/AllClubsOnMap") },
    { label: "List Of Games", action: () => (window.location.href = "/listofgames") },
    { label: "Subscription", action: () => (window.location.href = "/subscriptionscreen") },
    { label: "Contact Us", action: () => (window.location.href = "/contactus") },
    { label: "Privacy Policy", action: handleMenuClose },
    { label: "Terms And Conditions", action: () => (window.location.href = "/tandc") },
    { label: "Logout", action: () => (window.location.href = "/logout") },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: Colors.black,
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        zIndex: 1100,
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          sx={{ marginRight: "16px" }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              minWidth: "200px",
            },
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                item.action();
                handleMenuClose();
              }}
              sx={{
                "&:hover": {
                  backgroundColor: Colors.primaryLight,
                  color: Colors.primaryDark,
                },
                fontSize: "16px",
                padding: "10px 20px",
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        <Typography
          variant="h4"
          sx={{
            flexGrow: 1,
            textAlign: "center",
            fontWeight: "bold",
            color: Colors.white,
            cursor: "pointer",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
          onClick={() => (window.location.href = "./dashboard")}
        >
          FixtureFix
        </Typography>
        <img
          src={logo}
          alt="logo"
          height={75}
          onClick={() => (window.location.href = "/")}
          style={{
            cursor: "pointer",
            borderRadius: "50%",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderComponent;
